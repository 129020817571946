	$(function() {
		$(".por_sel ul li").on("click", function() {

			$(this).addClass("on").siblings().removeClass("on");
		});
	});

$(window).scroll(function(){
	if ($(this).scrollTop() > 80) {
		$('#header').addClass("act");
		$('#header .gnb_bg').css({"top":"80px"});
	} else {
		$('#header').removeClass("act");
		$('#header .gnb_bg').css({"top":"0"});
	}
});



$(document).ready(function(){
	$("#header .gnb_bg").hide(); 
	$("#gnb").on("mouseenter focusin", function(){					
		$(" .gnb_dpt2").stop().slideDown(300);
		$("#header .gnb_bg").stop().slideDown(300);
	
	});

	$("#header").on("mouseleave focusout", function(){					
		$("#gnb .gnb_dpt2").stop().slideUp(100);
		$("#header .gnb_bg").stop().slideUp(100);
		
	});



	$(".sel p").on("click", function(){
		if($(this).hasClass("on")){
			$(this).removeClass("on");
			$(this).parent().find("ul").stop().slideUp(100,"linear");			
		}else{
			$(this).addClass("on");
			$(this).parent().find("ul").stop().slideDown(100,"linear");
		}
	});

	/*footer link_site*/
	$(".link_site p").on("click", function(){
		if($(this).hasClass("on")){
			$(this).removeClass("on");
			$(this).parent().find("ul").stop().slideUp(500,"linear");			
		}else{
			$(this).addClass("on");
			$(this).parent().find("ul").stop().slideDown(500,"linear");
		}
	});
});

